import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TheGamersPage = () => {
  return (
    <Layout>
      <SEO title="The Gamers" />
      <h1>The Gamers page</h1>
      <p>To be added: shit to the gamers</p>
    </Layout>
  )
}

export default TheGamersPage
